import { Canvas } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import Experience from "../Experience.jsx";
import { useControls } from "leva";
import React from "react";

const WebGL = () => {
  return (
    <Canvas shadows gl={{ alpha: false }}>
      <PerspectiveCamera
        makeDefault
        position={[0, 0.15, 0]}
        fov={45}
        rotation-y={60}
      />
      <Experience />
    </Canvas>
  );
};

export default WebGL;
