/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 .\public\vhs\final.glb -o ./src/components/vhs/Final.tsx -t -s
*/

import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { Float } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import gsap from "gsap";

type GLTFResult = GLTF & {
  nodes: {
    VHSTapeWithCase003_CaseA1_0: THREE.Mesh;
    VHSTapeWithCase003_CaseA2_0: THREE.Mesh;
    VHSTapeWithCase003_CaseA3_0: THREE.Mesh;
    VHSTapeWithCase003_CaseA4_0: THREE.Mesh;
    VHSTapeWithCase003_CaseA5_0: THREE.Mesh;
    VHSTapeWithCase003_TapeA1_0: THREE.Mesh;
    VHSTapeWithCase003_TapeA2_0: THREE.Mesh;
    VHSTapeWithCase003_TapeA3_0: THREE.Mesh;
    VHSTapeWithCase003_TapeA4_0: THREE.Mesh;
    VHSTapeWithCase003_TapeA5_0: THREE.Mesh;
  };
  materials: {
    CaseA1: THREE.MeshStandardMaterial;
    CaseA2: THREE.MeshStandardMaterial;
    CaseA3: THREE.MeshStandardMaterial;
    CaseA4: THREE.MeshStandardMaterial;
    CaseA5: THREE.MeshStandardMaterial;
    TapeA1: THREE.MeshStandardMaterial;
    TapeA2: THREE.MeshStandardMaterial;
    TapeA3: THREE.MeshStandardMaterial;
    TapeA4: THREE.MeshStandardMaterial;
    TapeA5: THREE.MeshStandardMaterial;
  };
};

export function Final(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("/vhs/final.glb") as GLTFResult;
  const ref = useRef();
  const pauseDuration = 5; // Длительность паузы (секунды)
  const duration = 2;
  const scale = 0.15;
  const speed = 2;
  const floatIntensity = 0.1;
  const rotationIntensity = 1.15;
  const floatingRange = [0.05, 0.15];
  const tapePositionY = 0.18;

  useEffect(() => {
    const tl = gsap.timeline({
      defaults: { ease: "back.out(1.1)", duration },
      repeat: -1,
    });
    if (ref.current) {
      tl.to(
        ref.current.rotation,
        {
          y: THREE.MathUtils.degToRad(72),
        },
        `+=${pauseDuration}`
      )
        .to(
          ref.current.rotation,
          {
            y: THREE.MathUtils.degToRad(144),
          },
          `+=${pauseDuration}`
        )
        .to(
          ref.current.rotation,
          {
            y: THREE.MathUtils.degToRad(216),
          },
          `+=${pauseDuration}`
        )
        .to(
          ref.current.rotation,
          {
            y: THREE.MathUtils.degToRad(288),
          },
          `+=${pauseDuration}`
        )
        .to(
          ref.current.rotation,
          {
            y: THREE.MathUtils.degToRad(360),
          },
          `+=${pauseDuration}`
        );
    }

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <group {...props} dispose={null} ref={ref} position={[0, -0.1, 0]}>
      <Float
        speed={speed}
        floatIntensity={floatIntensity}
        rotationIntensity={rotationIntensity}
        floatingRange={floatingRange}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.VHSTapeWithCase003_CaseA1_0.geometry}
          material={materials.CaseA1}
          position={[0.309, 0.33, -0.951]}
          rotation={[0, -Math.PI / 10, 0]}
          scale={scale}
        />
      </Float>
      <Float
        speed={speed}
        floatIntensity={floatIntensity}
        rotationIntensity={rotationIntensity}
        floatingRange={floatingRange}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.VHSTapeWithCase003_CaseA2_0.geometry}
          material={materials.CaseA2}
          position={[-0.809, 0.33, -0.588]}
          rotation={[0, 0.942, 0]}
          scale={scale}
        />
      </Float>
      <Float
        speed={speed}
        floatIntensity={floatIntensity}
        rotationIntensity={rotationIntensity}
        floatingRange={floatingRange}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.VHSTapeWithCase003_CaseA3_0.geometry}
          material={materials.CaseA3}
          position={[-0.809, 0.33, 0.588]}
          rotation={[-Math.PI, 0.942, -Math.PI]}
          scale={scale}
        />
      </Float>
      <Float
        speed={speed}
        floatIntensity={floatIntensity}
        rotationIntensity={rotationIntensity}
        floatingRange={floatingRange}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.VHSTapeWithCase003_CaseA4_0.geometry}
          material={materials.CaseA4}
          position={[0.309, 0.33, 0.951]}
          rotation={[Math.PI, -Math.PI / 10, Math.PI]}
          scale={scale}
        />
      </Float>
      <Float
        speed={speed}
        floatIntensity={floatIntensity}
        rotationIntensity={rotationIntensity}
        floatingRange={floatingRange}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.VHSTapeWithCase003_CaseA5_0.geometry}
          material={materials.CaseA5}
          position={[1, 0.33, 0]}
          rotation={[0, -Math.PI / 2, 0]}
          scale={scale}
        />
      </Float>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.VHSTapeWithCase003_TapeA1_0.geometry}
        material={materials.TapeA1}
        position={[0.88, tapePositionY, 0]}
        rotation={[0, -Math.PI / 2, 0]}
        scale={scale}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.VHSTapeWithCase003_TapeA2_0.geometry}
        material={materials.TapeA2}
        position={[0.272, tapePositionY, 0.837]}
        rotation={[Math.PI, -Math.PI / 10, Math.PI]}
        scale={scale}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.VHSTapeWithCase003_TapeA3_0.geometry}
        material={materials.TapeA3}
        position={[0.272, tapePositionY, -0.837]}
        rotation={[0, -Math.PI / 10, 0]}
        scale={scale}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.VHSTapeWithCase003_TapeA4_0.geometry}
        material={materials.TapeA4}
        position={[-0.712, tapePositionY, -0.517]}
        rotation={[0, 0.942, 0]}
        scale={scale}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.VHSTapeWithCase003_TapeA5_0.geometry}
        material={materials.TapeA5}
        position={[-0.712, tapePositionY, 0.517]}
        rotation={[-Math.PI, 0.942, -Math.PI]}
        scale={scale}
      />
    </group>
  );
}

useGLTF.preload("/vhs/final.glb");
