import {
  OrbitControls,
  Stage,
  MeshReflectorMaterial,
  Environment,
  Center,
} from "@react-three/drei";
import { Perf } from "r3f-perf";

import { Common } from "./components/vhs/Common";
import { Upfonts } from "./components/vhs/Upfonts";
import { Final } from "./components/vhs/Final";

export default function Experience() {
  return (
    <>
      <Perf position="top-left" />

      {/* <OrbitControls makeDefault /> */}
      <fog attach="fog" args={["#151515", 1, 10]} />
      <Final />
      {/* <Upfonts /> */}
      {/* <Common /> */}
      <color attach="background" args={["#17171b"]} />
      <ambientLight intensity={0.5} />
      <directionalLight
        castShadow
        intensity={1}
        position={[10, 6, 6]}
        shadow-mapSize={[1024, 1024]}
      ></directionalLight>

      <Environment preset="dawn" />
      <mesh position={[0, -0.1, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[25, 25]} />
        <MeshReflectorMaterial
          blur={[100, 100]}
          resolution={1024}
          mixBlur={4}
          mixStrength={100}
          depthScale={1}
          minDepthThreshold={25}
          color="#151515"
          metalness={0.75}
          roughness={0.65}
        />
      </mesh>
    </>
  );
}
